import { Box, Flex, Text, VStack, HStack, Image, StatNumber, StatHelpText, Stat, StatLabel, Divider, Spinner, Link } from "@chakra-ui/react";
import { Points } from "../../rest/kairos/types";
import { ArrowForwardIcon } from "@chakra-ui/icons";

interface Props {
    points: Points;
    showLeaderboard: boolean
}

export const Warchest = ({ points, showLeaderboard }: Props) => {
    return (
        <Box bg="#18181b" p={8} className="mint-box" maxW="100%">
            <Flex alignItems="center" gap={8}>
                <Box flexShrink={0}>
                    <Image
                        src={`/kyros/warchest.png`}
                        boxSize="50px"
                        alt="Warchest"
                    />
                </Box>
                <Box>
                    <VStack alignItems="flex-start" spacing={2}>
                        <Flex alignItems="center" gap={2}>
                            <Text
                                fontSize="lg"
                                fontWeight="semibold"
                                color="white"
                            >
                                Your Warchest 🪙
                            </Text>
                        </Flex>
                        <Text
                            fontSize="md"
                            color="whiteAlpha.800"
                        >
                            Hold kySOL or kyJTO, add liquidity and use it in DeFi to get more points.
                        </Text>
                        {showLeaderboard && (
                            <Link href="/leaderboard" fontSize="md" color="white" fontStyle="bold">
                                View Leaderboard <ArrowForwardIcon />
                            </Link>
                        )}
                        {points ? (
                            <Box mt={8}>
                                <Box display={{ base: "none", md: "block" }}>
                                    <HStack spacing={10}>
                                        <Stat>
                                            <StatLabel>Total</StatLabel>
                                            <StatNumber>{points.points.toLocaleString(undefined, { maximumFractionDigits: 0 })}</StatNumber>
                                            <StatHelpText>
                                                {points.points === 0 ? `#${points.totalUsers}` : `#${points.rank}`}
                                            </StatHelpText>
                                        </Stat>

                                        <Divider orientation="vertical" width={"1px"} height={"30px"} />

                                        <Stat>
                                            <StatLabel>kySOL</StatLabel>
                                            <StatNumber>{points.holdingPoints.toLocaleString(undefined, { maximumFractionDigits: 0 })}</StatNumber>
                                        </Stat>
                                        <Stat>
                                            <StatLabel>kyJTO</StatLabel>
                                            <StatNumber>{points.jtoHoldingPoints.toLocaleString(undefined, { maximumFractionDigits: 0 })}</StatNumber>
                                        </Stat>
                                        <Stat>
                                            <StatLabel>Liquidity</StatLabel>
                                            <StatNumber>{points.liquidityPoints.toLocaleString(undefined, { maximumFractionDigits: 0 })}</StatNumber>
                                        </Stat>
                                        <Stat>
                                            <StatLabel>DeFi</StatLabel>
                                            <StatNumber>{points.defiPoints.toLocaleString(undefined, { maximumFractionDigits: 0 })}</StatNumber>
                                        </Stat>
                                        <Stat>
                                            <StatLabel>Referrals</StatLabel>
                                            <StatNumber>{points.referralPoints.toLocaleString(undefined, { maximumFractionDigits: 0 })}</StatNumber>
                                        </Stat>
                                    </HStack>
                                    {points.pointsUpdated && (
                                        <Text
                                            fontSize="sm"
                                            color="gray.500"
                                            fontStyle="italic"
                                            mt={2}
                                        >
                                            Your points might have changed recently due to a double-counting error. We apologize for the inconvenience.
                                        </Text>
                                    )}
                                    <Text
                                        fontSize="sm"
                                        color="gray.500"
                                        fontStyle="italic"
                                        mt={2}
                                    >
                                        Points are refreshed every 24 hours.
                                    </Text>
                                </Box>
                                <Box display={{ base: "block", lg: "none" }}>
                                    <VStack spacing={4} align="flex-start">
                                        <Flex>
                                            <Stat>
                                                <StatLabel>Total</StatLabel>
                                                <StatNumber>{points.points.toLocaleString(undefined, { maximumFractionDigits: 0 })}</StatNumber>
                                                <StatHelpText>
                                                    {points.points === 0 ? `#${points.totalUsers}` : `#${points.rank}`}
                                                </StatHelpText>
                                            </Stat>
                                        </Flex>

                                        <Divider orientation="horizontal" width={"100%"} height={"1px"} />

                                        <HStack spacing={4}>
                                            <Stat size={"sm"}>
                                                <StatLabel>kySOL</StatLabel>
                                                <StatNumber>{points.holdingPoints.toLocaleString(undefined, { maximumFractionDigits: 0 })}</StatNumber>
                                            </Stat>
                                            <Stat size={"sm"}>
                                                <StatLabel>kyJTO</StatLabel>
                                                <StatNumber>{points.jtoHoldingPoints.toLocaleString(undefined, { maximumFractionDigits: 0 })}</StatNumber>
                                            </Stat>
                                        </HStack>

                                        <HStack spacing={4}>
                                            <Stat size={"sm"}>
                                                <StatLabel>Liquidity</StatLabel>
                                                <StatNumber>{points.liquidityPoints.toLocaleString(undefined, { maximumFractionDigits: 0 })}</StatNumber>
                                            </Stat>
                                            <Stat size={"sm"}>
                                                <StatLabel>DeFi</StatLabel>
                                                <StatNumber>{points.defiPoints.toLocaleString(undefined, { maximumFractionDigits: 0 })}</StatNumber>
                                            </Stat>
                                        </HStack>

                                        <HStack spacing={4}>
                                            <Stat size={"sm"}>
                                                <StatLabel>Referrals</StatLabel>
                                                <StatNumber>{points.referralPoints.toLocaleString(undefined, { maximumFractionDigits: 0 })}</StatNumber>
                                            </Stat>
                                        </HStack>
                                    </VStack>
                                    <Text
                                            fontSize="sm"
                                            color="gray.500"
                                            fontStyle="italic"
                                            mt={4}
                                        >
                                            Your points might have changed recently due to a double-counting error. We apologize for the inconvenience.
                                        </Text>
                                    <Text
                                        fontSize="sm"
                                        color="gray.500"
                                        fontStyle="italic"
                                        mt={4}
                                    >
                                        Points are refreshed every 24 hours.
                                    </Text>
                                </Box>
                            </Box>
                        ) : (
                            <Spinner />
                        )}
                    </VStack>
                </Box>
            </Flex>
        </Box>
    );
}
