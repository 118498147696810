import { useCallback, useContext, useState } from "react";
import { LAMPORTS_PER_SOL, PublicKey, TransactionInstruction, SystemProgram, Keypair } from "@solana/web3.js";
import {
    createAssociatedTokenAccountIdempotentInstruction,
    getAssociatedTokenAddress,
    TOKEN_PROGRAM_ID
} from "@solana/spl-token";
import { useAnchorWallet, useConnection, useWallet } from "@solana/wallet-adapter-react";
import { AppConfigContext } from "../context";
import { createMintToPayload, updateVaultBalancePayload, depositSolPayload } from "../helpers/payloads";
import { sendTx } from "./utils/utils";
import mixpanel from "mixpanel-browser";
import { useJitoSOLRate } from "./useJitoSOLRate";

export const useMintTo = () => {
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();
    const wallet = useAnchorWallet()
    const { environment, priorityFeeLevel, solVrtMintAddress, kyrosFeeWalletAddress, vaultProgramAddress, vaultConfigAddress, jitoSolMintAddress } = useContext(AppConfigContext)
    const { fetchConversionRate } = useJitoSOLRate()

    const jitoSolMint = new PublicKey(jitoSolMintAddress)
    const vaultConfig = new PublicKey(vaultConfigAddress)
    const vaultProgram = new PublicKey(vaultProgramAddress)
    const feeWallet = new PublicKey(kyrosFeeWalletAddress)
    const staker = publicKey

    const stakePoolProgram = new PublicKey("SPoo1Ku8WFXoNDMHPsrGSTSG1Y47rzgn41SLUNakuHy")
    const jitoStakePool = new PublicKey("Jito4APyf642JPZPx3hGc6WWJ8zPKtRbRs4P815Awbb")
    const stakePoolWithdrawAuthority = new PublicKey("6iQKfEyhr3bZMotVkW6beNZz5CPAkiwvgV2CTje9pVSS")
    const reserveStakeAccount = new PublicKey("BgKUXdS29YcHCFrPm5M8oLHiTzZaMDjsebggjoaQ6KFL")
    const stakeFeeAccount = new PublicKey("feeeFLLsam6xZJFc6UQFrHqkvVt4jfmVvi2BRLkUZ4i")

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const mintTo = useCallback(async (vrtMintAddress: string, supportedTokenMintAddress: string, vaultAddress: string, amount: string, isNative?: boolean) => {
        if (!staker) {
            setError("Please connect your wallet.")
            return null
        }

        if (amount === '' || Number(amount) === 0) {
            setError("Please enter an amount greater than 0.")
            return null
        }

        if (!wallet) {
            setError("Please connect your wallet.")
            return null
        }

        const vrtMint = new PublicKey(vrtMintAddress)
        const supportedTokenMint = new PublicKey(supportedTokenMintAddress)
        const vault = new PublicKey(vaultAddress)
        if (isNative === true && vrtMintAddress === solVrtMintAddress) {
            mixpanel.track('Deposit Native', {
                'sol_amount': amount,
                'wallet': wallet.publicKey?.toBase58(),
                'native': isNative
            })
        } else {
            mixpanel.track('Deposit', {
                'kysol_amount': amount,
                'wallet': wallet.publicKey?.toBase58(),
                'native': isNative
            })
        }

        let ixs: TransactionInstruction[] = []
        let finalAmountToMint = 0;
        let finalAmountToMintLess = 0;
        const temporaryAccount = Keypair.generate()

        const amountInLamports = Math.round(Number(amount) * LAMPORTS_PER_SOL) // Amount of SOL/kySOL we deposit

        if (isNative === true && vrtMintAddress === solVrtMintAddress) {
            if (environment === "devnet") {
                // Not available on devnet
                setError("Direct SOL minting is not available on devnet.")
                return null
            }

            // Wrap SOL to JitoSOL
            const jitoAmountToMint = await fetchConversionRate(connection, amount)
            if (!jitoAmountToMint) {
                setError("Error fetching JitoSOL rate")
                return null
            }

            // Amount of JitoSOL we receive
            const jitoAmountOutLamports = Math.floor(Number(jitoAmountToMint) * LAMPORTS_PER_SOL).toFixed(0)
            const jitoAmountOutLamportsSlippage = Math.floor(Number(jitoAmountToMint) * 0.98 * LAMPORTS_PER_SOL).toFixed(0)

            // 0.1 Transfer SOL to temporary account
            const transferToTempIx = SystemProgram.transfer({
                fromPubkey: staker,
                toPubkey: temporaryAccount.publicKey,
                lamports: amountInLamports
            });
            ixs.push(transferToTempIx);

            const stakerJitoSolAta = await getAssociatedTokenAddress(
                jitoSolMint,
                staker
            );

            // 0.2 Create ATA for staker's jitoSol
            const createIdempotentStakerJitoSolAtaIx = createAssociatedTokenAccountIdempotentInstruction(
                staker,
                stakerJitoSolAta,
                staker,
                jitoSolMint
            )
            ixs.push(createIdempotentStakerJitoSolAtaIx)

            // 0.3 StakePool DepositSOL
            const depositSolIx = new TransactionInstruction({
                keys: [
                    { pubkey: jitoStakePool, isSigner: false, isWritable: true }, // stake pool
                    { pubkey: stakePoolWithdrawAuthority, isSigner: false, isWritable: false }, // stake pool withdraw authority
                    { pubkey: reserveStakeAccount, isSigner: false, isWritable: true }, // reserve stake account
                    { pubkey: temporaryAccount.publicKey, isSigner: true, isWritable: true }, // depositer
                    { pubkey: stakerJitoSolAta, isSigner: false, isWritable: true }, // user account
                    { pubkey: stakeFeeAccount, isSigner: false, isWritable: true }, // fee account
                    { pubkey: stakerJitoSolAta, isSigner: false, isWritable: true }, // referral fee account
                    { pubkey: jitoSolMint, isSigner: false, isWritable: true }, // pool token mint
                    { pubkey: SystemProgram.programId, isSigner: false, isWritable: false }, // system program
                    { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false }, // token program
                ],
                programId: stakePoolProgram,
                data: depositSolPayload(amountInLamports)
            })
            ixs.push(depositSolIx)

            // From the x amount of SOL we deposit, we get the y amount of JitoSOL that we use to mint VRT
            finalAmountToMint = Number(jitoAmountOutLamports)
            finalAmountToMintLess = Number(jitoAmountOutLamportsSlippage)
        } else {
            // We use directly the x amount of kySOL to mint VRT
            finalAmountToMint = amountInLamports
            finalAmountToMintLess = amountInLamports * 0.98
        }

        const stakerSupportedTokenAta = await getAssociatedTokenAddress(
            supportedTokenMint,
            staker,
            true
        );

        try {
            // 1. ATA for VRT / staker
            const stakerVrtAta = await getAssociatedTokenAddress(
                vrtMint,
                staker,
                true
            );

            const createIdempotentStakerVrtAtaIx = createAssociatedTokenAccountIdempotentInstruction(
                staker,
                stakerVrtAta,
                staker,
                vrtMint
            )
            ixs.push(createIdempotentStakerVrtAtaIx)

            // 2. ATA for supported token / vault
            const vaultSupportedTokenAta = await getAssociatedTokenAddress(
                supportedTokenMint,
                vault,
                true
            );

            const createIdempotentVaultSupportedTokenAtaIx = createAssociatedTokenAccountIdempotentInstruction(
                staker,
                vaultSupportedTokenAta,
                vault,
                supportedTokenMint
            )
            ixs.push(createIdempotentVaultSupportedTokenAtaIx)

            // 3. ATA for VRT / fee wallet
            const feeWalletVrtAta = await getAssociatedTokenAddress(
                vrtMint,
                feeWallet,
                true
            );

            const createIdempotentFeeWalletVrtAtaIx = createAssociatedTokenAccountIdempotentInstruction(
                staker,
                feeWalletVrtAta,
                feeWallet,
                vrtMint
            )
            ixs.push(createIdempotentFeeWalletVrtAtaIx)

            // 4. Update vault balance
            const updateVaultBalanceInstruction = new TransactionInstruction({
                keys: [
                    { pubkey: vaultConfig, isSigner: false, isWritable: false },  // Config account
                    { pubkey: vault, isSigner: false, isWritable: true },    // Vault account
                    { pubkey: vaultSupportedTokenAta, isSigner: false, isWritable: true },  // Vault's token account
                    { pubkey: vrtMint, isSigner: false, isWritable: true },  // Token mint account
                    { pubkey: feeWalletVrtAta, isSigner: false, isWritable: true },  // Vault fee token account
                    { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false }, // System program (or Token Program if required)
                ],
                programId: vaultProgram,
                data: updateVaultBalancePayload()
            });
            ixs.push(updateVaultBalanceInstruction)

            // 5. Mint VRT
            const mintToIx = new TransactionInstruction({
                keys: [
                    { pubkey: vaultConfig, isSigner: false, isWritable: false },  // Config account
                    { pubkey: vault, isSigner: false, isWritable: true },    // Vault account
                    { pubkey: vrtMint, isSigner: false, isWritable: true },  // Token mint account
                    { pubkey: staker, isSigner: true, isWritable: true }, // Staker account
                    { pubkey: stakerSupportedTokenAta, isSigner: false, isWritable: true }, // Staker's token account
                    { pubkey: vaultSupportedTokenAta, isSigner: false, isWritable: true },  // Vault's token account
                    { pubkey: stakerVrtAta, isSigner: false, isWritable: true }, // Staker's vrt token account
                    { pubkey: feeWalletVrtAta, isSigner: false, isWritable: true },  // Vault fee token account
                    { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false }, // System program (or Token Program if required)
                    { pubkey: vaultProgram, isSigner: false, isWritable: true },  // Vault program
                ],
                programId: vaultProgram,
                data: createMintToPayload(Math.round(finalAmountToMint), Math.round(finalAmountToMintLess))
            });
            ixs.push(mintToIx)

            if (isNative) {
                return await sendTx(environment, connection, staker, sendTransaction, ixs, priorityFeeLevel, [temporaryAccount])
            } else {
                return await sendTx(environment, connection, staker, sendTransaction, ixs, priorityFeeLevel, [])
            }
        } catch (err) {
            setError(`Something went wrong with the transaction: ${err}`)
            return null
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [staker, wallet]);

    return { mintTo, loading, error };
};
