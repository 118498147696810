import { Connection } from "@solana/web3.js";

export async function getEpochStartDate(connection: Connection, epoch: number) {
    const epochSchedule = await connection.getEpochSchedule();
    const currentEpochInfo = await getCurrentEpochInfo(connection);
    const averageSlotTime = await getAverageSlotTime(); // in seconds

    if (epoch < currentEpochInfo.epoch) {
        throw new Error("This function is designed for current or future epochs.");
    }

    // Calculate the starting slot of the future epoch
    const futureEpochStartSlot = epochSchedule.slotsPerEpoch * epoch + epochSchedule.firstNormalSlot;

    // Get the block time for the current slot
    const currentBlockTime = await connection.getBlockTime(currentEpochInfo.absoluteSlot);

    if (currentBlockTime === null) {
        throw new Error('Unable to fetch current block time.');
    }

    // Calculate the number of slots between the current slot and the future epoch start slot
    const slotsUntilFutureEpoch = futureEpochStartSlot - currentEpochInfo.absoluteSlot;

    // Calculate the time difference (in seconds) between the current slot and the future epoch's start slot
    const timeToFutureEpoch = slotsUntilFutureEpoch * averageSlotTime;

    // Calculate the start date of the future epoch
    const futureEpochStartDate = new Date((currentBlockTime + timeToFutureEpoch) * 1000);
    
    return futureEpochStartDate;
}

export const fetchUnstakeTime = async (connection: Connection, slotUnstaked: number, currentSlot: number) => {
  const unstakeEpoch = Math.floor(slotUnstaked / 432000) 
  const withdrawableEpoch = unstakeEpoch + 2
  const withdrawableSlot = withdrawableEpoch * 432000

  let availableDate: Date | null = null
  let estimatedTimeToClaim: string | null = null
  if (withdrawableSlot > currentSlot) {
    const slotDiff = withdrawableSlot - currentSlot
    const timeDiff = slotDiff * 0.4
    availableDate = new Date(Date.now() + (timeDiff * 1000))
    const days = Math.floor(timeDiff / (24 * 3600));
    const hours = Math.floor((timeDiff % (24 * 3600)) / 3600);
    estimatedTimeToClaim = `~${days}d ${hours}h`;
  }
  return { availableEpoch: withdrawableEpoch, availableSlot: withdrawableSlot, availableDate: availableDate, estimatedTimeToClaim: estimatedTimeToClaim }
}

// Function to get the average slot time
async function getAverageSlotTime(): Promise<number> {
    const slotTime = 0.4; // Average slot time on Solana is approximately 0.4 seconds
    return slotTime;
}

// Function to get the current epoch info
export async function getCurrentEpochInfo(connection: Connection) {
    const epochInfo = await connection.getEpochInfo();
    return epochInfo;
}
