import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  VStack,
  Text,
  Input,
  InputLeftAddon,
  InputGroup,
  Image,
  HStack,
  Portal,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
  Tooltip,
  Divider,
} from "@chakra-ui/react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { useFormik } from "formik";
import { useEffect, useContext, useState } from "react";
import { useErrorToast, useSuccessToast } from "../helpers/toast";
import { AppConfigContext } from "../context";
import { useBalance } from "../hooks/useBalance";
import { ArrowDownIcon, ChevronDownIcon, InfoIcon } from "@chakra-ui/icons";
import { useMintTo } from "../hooks/useMintTo";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { NcnTable, UncappedTvl } from "../components";
import { useKyrosApy } from "../hooks/useKyrosApy";
import { CTA } from "../components";
import "../styles/MintBox.css";
import { useJitoSOLRate } from "../hooks/useJitoSOLRate";
import { useNativeBalance } from "../hooks/useNativeBalance";
import { solAmountFormatter } from "../helpers/formatter";
import { useLstBalances } from "../hooks/useLstBalances";
import { useTokensDeposited } from "../hooks/useTokensDeposited";
import { useJitoSOLPrice } from "../hooks/useJitoSOLPrice";
import { useNcns } from "../hooks/useNcns";
import { useExchangeRate } from "../hooks/useExchangeRate";

export const RestakeSolPage = () => {
  const { apy: kyrosApy } = useKyrosApy();

  const { publicKey } = useWallet();
  const { connection } = useConnection();
  const { solVrtMintAddress, jitoSolMintAddress, solVaultMaxCap, solVaultPaused, solVaultAddress } = useContext(AppConfigContext);
  const { price } = useJitoSOLPrice();
  
  const [selectedToken, setSelectedToken] = useState<string>("jitosol");
  const [displayedBalance, setDisplayedBalance] = useState<string>("0 JitoSOL");
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const [capReached, setCapReached] = useState<boolean>(false);

  const { balance: jitoSolBalance, fetchBalance: fetchJitoSolBalance } = useBalance(new PublicKey(jitoSolMintAddress));
  const { fetchBalance: fetchKySolBalance } = useBalance(new PublicKey(solVrtMintAddress));
  const { balance: solBalance, fetchNativeBalance } = useNativeBalance();
  const { fetchLstAvailable } = useLstBalances();
  const { errorToast } = useErrorToast();
  const { successToast } = useSuccessToast();
  const { mintTo, error: mintToError } = useMintTo();
  const { rate: kySolRate, fetchRate: fetchKySolRate } = useExchangeRate();
  const { tokensDeposited, isLoading: isTokensDepositedLoading, error: tokensDepositedError, fetchTokensDeposited } = useTokensDeposited();
  const { solNcns: ncns, fetchNcns } = useNcns();

  const estimatedKySolRate = 1;
  const estimatedJitoSolRate = 1.148;
  const { rate: jitoSolRate, fetchRate } = useJitoSOLRate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    let receivedValue = inputValue;
    if (selectedToken === "sol") {
      receivedValue = inputValue ? `≈ ${solAmountFormatter.format(Number(inputValue) * (jitoSolRate ?? estimatedJitoSolRate) * (kySolRate ?? estimatedKySolRate))}` : "";
    } else {
      receivedValue = inputValue ? `${solAmountFormatter.format(Number(inputValue) * (kySolRate ?? estimatedKySolRate))}` : "";
    }

    formik.setValues({
      amount: inputValue,
      receivedAmount: receivedValue
    });
  };

  const handleMaxClick = () => {
    const maxBalance = selectedToken === "sol"
      ? solBalance || "0"
      : jitoSolBalance || "0";

    // Get max amount considering vault cap
    const remainingCap = solVaultMaxCap - (Number(tokensDeposited) || 0);
    const maxAmount = solAmountFormatter.format(Math.min(Number(maxBalance), remainingCap));

    // Calculate received amount based on selected token
    let receivedAmount;
    if (selectedToken === "sol") {
      receivedAmount = `≈ ${solAmountFormatter.format(Number(maxAmount) * (jitoSolRate ?? estimatedJitoSolRate) * (kySolRate ?? estimatedKySolRate))}`;
    } else {
      receivedAmount = `${solAmountFormatter.format(Number(maxAmount) * (kySolRate ?? estimatedKySolRate))}`;
    }

    formik.setValues({
      amount: maxAmount.replace(/,/g, ""),
      receivedAmount: receivedAmount.replace(/,/g, "")
    });
  };

  const formik = useFormik({
    initialValues: {
      amount: "",
      receivedAmount: "",
    },
    onSubmit: async (values) => {
      try {
        const signature = await mintTo(solVrtMintAddress, jitoSolMintAddress, solVaultAddress, values.amount, selectedToken === "sol");
        if (signature) {
          successToast("Your mint was successful!");
          reload();
        }
      } catch (err) {
        errorToast(`${err}`);
      }
    },
  });

  useEffect(() => {
    if (mintToError) {
      errorToast(mintToError);
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mintToError]);

  const reload = () => {
    formik.resetForm();
    fetchJitoSolBalance(publicKey);
    fetchKySolBalance(publicKey);
    fetchNativeBalance(publicKey);
    fetchRate(connection);
    fetchTokensDeposited();
    fetchNcns();
    fetchKySolRate(solVaultAddress);
  };

  useEffect(() => {
    setDisplayedBalance(
      selectedToken === "sol"
        ? solAmountFormatter.format(Number(solBalance?.toString())) + " SOL"
        : solAmountFormatter.format(Number(jitoSolBalance?.toString())) + " JitoSOL"
    );
  }, [jitoSolBalance, solBalance, selectedToken]);

  useEffect(() => {
    setFormDisabled(
      selectedToken === "sol"
        ? formik.values.amount > (solBalance?.toString() || "0")
        : formik.values.amount > (jitoSolBalance?.toString() || "0")
    );
  }, [formik.values.amount, jitoSolBalance, solBalance, selectedToken]);

  useEffect(() => {
    fetchJitoSolBalance(publicKey);
    fetchKySolBalance(publicKey);
    fetchRate(connection);
    fetchNativeBalance(publicKey);
    fetchLstAvailable(publicKey);
    fetchTokensDeposited();
    fetchNcns();
    fetchKySolRate(solVaultAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  useEffect(() => {
    const supply = Number(tokensDeposited);
    const receivedAmount = Number(formik.values.receivedAmount.replace(/[^0-9.]/g, ''));
    setFormDisabled(supply > solVaultMaxCap || (supply + receivedAmount) > solVaultMaxCap);
  }, [solVaultMaxCap, tokensDeposited, formik.values.receivedAmount]);

  useEffect(() => {
    if (tokensDeposited) {
      setCapReached(Number(tokensDeposited) >= solVaultMaxCap * 0.99999);
    }
  }, [tokensDeposited, solVaultMaxCap]);

  useEffect(() => {
    if (tokensDeposited) {
      setCapReached(Number(tokensDeposited) >= solVaultMaxCap * 0.99999);
    }
  }, [tokensDeposited, solVaultMaxCap]);

  const { setVisible } = useWalletModal();
  const { connecting } = useWallet();

  return (
    <>
      <Flex direction="column" minHeight="100vh">
        <Container
          maxW="520px"
          flex="1"
          py={8}
          display="flex"
          justifyContent="center"
        >
          <VStack spacing={8} align="stretch">
            <Flex alignItems="center" justifyContent="flex-start">
              <Text
                fontWeight="bold"
                fontSize="2xl"
                color="white"
                lineHeight="1.2"
                mr={2}
              >
                Get kySOL
              </Text>
              {kyrosApy !== null && (
                <Badge
                  colorScheme="green"
                  borderRadius="full"
                  px={4}
                  py={1}
                  fontSize="md"
                  display="flex"
                  alignItems="center"
                  height="auto"
                >
                  ≈ {(kyrosApy?.sol * 100).toFixed(2)}% APY
                  <Tooltip label={
                    <>
                      <VStack spacing={2}>
                        <HStack justifyContent="space-between" width="100%">
                          <Text fontSize="xs" textAlign="left">JitoSOL</Text>
                          <Text fontSize="xs" textAlign="right" fontWeight="bold">{(kyrosApy?.solJitoApy * 100).toFixed(2)}% APY</Text>
                        </HStack>
                        <HStack justifyContent="space-between" width="100%">
                          <Text fontSize="xs" textAlign="left">TipRouter</Text>
                          <Text fontSize="xs" textAlign="right" fontWeight="bold">{((kyrosApy?.solTipRouterApy) * 100).toFixed(2)}% APY</Text>
                        </HStack>
                        <Divider />
                        <HStack justifyContent="space-between" width="100%">
                          <Text fontSize="xs" textAlign="left">Total</Text>
                          <Text fontSize="xs" textAlign="right" fontWeight="bold">{(kyrosApy?.sol * 100).toFixed(2)}% APY</Text>
                        </HStack>
                      </VStack>
                      <Divider mt={2} mb={2} />
                      <VStack spacing={2} align="flex-start">
                        <Text fontSize="xs" textAlign="left">Trailing APY:</Text>
                        <HStack justifyContent="space-between" width="100%">
                          <Text fontSize="xs" textAlign="left">14-day</Text>
                          <Text fontSize="xs" textAlign="right" fontWeight="bold">{(kyrosApy?.sol14d * 100).toFixed(2)}% APY</Text>
                        </HStack>
                        <HStack justifyContent="space-between" width="100%">
                          <Text fontSize="xs" textAlign="left">28-day</Text>
                          <Text fontSize="xs" textAlign="right" fontWeight="bold">{(kyrosApy?.sol28d * 100).toFixed(2)}% APY</Text>
                        </HStack>
                      </VStack>
                    </>
                  } width="500px">
                    <InfoIcon ml={1} />
                  </Tooltip>
                </Badge>
              )}
            </Flex>

            <Text fontSize="md" fontWeight="400" color="#CCCCCC">
              kySOL combines staking, MEV, and restaking rewards into one token for optimized returns.
            </Text>
            <UncappedTvl currency="kySOL" price={price} totalSupply={tokensDeposited} isTotalSupplyLoading={isTokensDepositedLoading} totalSupplyError={tokensDepositedError} />

            <Flex justifyContent="space-between" alignItems="stretch" gap={4}>
              <CTA
                destination="/defi"
                destinationTracking="kyros-defi"
                image="/kySOLLogo.svg"
                title={"kySOL x DeFi"}
                description="Lending, LP and Yield Trading now available with boosted points!"
              />
              {/* Temporary removal of LSTs to put kyJTO in the spotlight */}
              {/* {!isLstAvailableLoading && lstAvailable !== null && lstAvailable.available > 0 ? (
                <CTA
                  destination="/lst"
                  destinationTracking="lst"
                  image={lstAvailable.lst.metadata?.imageUri ?? "/kySOLLogo.svg"}
                  title={`Earn more rewards on ${lstAvailable.lst.metadata?.symbol ?? "LSTs"}`}
                  description={`One click convert your ${lstAvailable.available} LSTs to kySOL`}
                />
              ) : (
                <CTA
                  destination="https://jup.ag/swap/SOL-kySo1nETpsZE2NWe5vj2C64mPSciH1SppmHb4XieQ7B"
                  destinationTracking="jupiter"
                  image="/kySOLLogo.svg"
                  title="Buy kySOL"
                  description="Swap any token to kySOL on Jupiter"
                />
              )} */}
              <CTA
                destination="/jto"
                destinationTracking="kyjto"
                image="/kyjto.svg"
                title="Restake your JTO"
                description="Mint kyJTO, earn yield on your Jito tokens and earn more Warchest points!"
              />
            </Flex>

            <Box className="mint-box">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb={4}
                mt={4}
              >
                <Text fontSize="md" fontWeight="semibold" color="white">
                  (RE)STAKE
                </Text>
                {publicKey && (
                  <HStack spacing={2} alignItems="center">
                    <Image src="/wallet-icon.svg" boxSize="16px" />
                    <Text fontSize="sm" fontWeight="light" color="white">
                      {displayedBalance}
                    </Text>
                    <Button
                      size="sm"
                      onClick={handleMaxClick}
                      variant="ghost"
                      color="whiteAlpha.800"
                      _hover={{ bg: "whiteAlpha.200" }}
                      backgroundColor="whiteAlpha.300"
                      fontWeight="normal"
                      paddingX={2}
                      isDisabled={capReached}
                    >
                      MAX
                    </Button>
                  </HStack>
                )}
              </Flex>

              <form onSubmit={formik.handleSubmit}>
                <InputGroup
                  mb={4}
                  sx={{
                    borderRadius: "8px",
                    borderColor: "gray.500",
                  }}
                >

                  <InputLeftAddon
                    p={0}
                    sx={{
                      color: "white.200",
                      paddingX: "12px",      // Match padding with input box
                      fontSize: "xl",        // Match font size
                      height: "60px",        // Match height with input box
                      display: "flex",
                      alignItems: "center",  // Center the text vertically
                      borderWidth: "0.1px",
                      borderColor: "#403D3B",
                      _hover: { color: "white.300" },
                      width: "150px",        // Fixed width
                    }}
                  >
                    <Menu placement="bottom">
                      <MenuButton
                        as={Button}
                        variant="unstyled" // Remove theme styling
                        borderRadius={0}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center", // Center content horizontally
                          fontWeight: "bold",
                          color: "white",
                          padding: 0,        // Remove extra padding
                          height: "60px",    // Match the height
                          zIndex: 1,         // Ensure the button is above other elements
                          width: "100%",     // Occupy full width of the parent (130px)
                          role: "group" // Enables group hover
                        }}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          width="100%"              // Occupy full width
                          justifyContent="center"   // Center content horizontally
                        >
                          <Image
                            src={selectedToken === "sol" ? "/solana.png" : "/jitosol.png"}
                            boxSize="25px"
                            borderRadius="full"
                          />
                          <Text fontWeight="bold" ml={1} color="white">
                            {selectedToken === "sol" ? "SOL" : "JitoSOL"}
                          </Text>
                          <ChevronDownIcon
                            ml={1}
                            color="white"
                            transition="all 0.3s ease" // Adds smooth transition
                            _groupHover={{
                              color: "#5DF0EE", // Example color change
                            }}
                          />
                        </Box>
                      </MenuButton>
                      <Portal>
                        <MenuList
                          zIndex={1000} // Set a high zIndex to ensure visibility
                          left="50%"    // Center horizontally
                          transform="translateX(-50%)"
                          backgroundColor="#18181b"
                        >
                          <MenuItem
                            key={"sol"}
                            onClick={() => {
                              setSelectedToken("sol");
                              // Recalculate received amount when switching to SOL
                              const newReceivedAmount = formik.values.amount
                                ? `≈ ${(Number(formik.values.amount) * (jitoSolRate ?? estimatedJitoSolRate)).toFixed(9)}`
                                : "";
                              formik.setValues({
                                ...formik.values,
                                receivedAmount: newReceivedAmount
                              });
                            }}
                            margin={0}
                            backgroundColor="#18181b"
                          >
                            <Box display="flex" alignItems="center">
                              <Image src={"/solana.png"} boxSize="24px" mr="2" />
                              SOL
                            </Box>
                          </MenuItem>
                          <MenuItem
                            key={"jitosol"}
                            onClick={() => {
                              setSelectedToken("jitosol");
                              // When switching to jitoSOL, received amount equals input amount
                              formik.setValues({
                                ...formik.values,
                                receivedAmount: formik.values.amount
                              });
                            }}
                            margin={0}
                            backgroundColor="#18181b"
                          >
                            <Box display="flex" alignItems="center" mr="2">
                              <Image src={"/jitosol.png"} boxSize="24px" mr="2" />
                              JitoSOL
                            </Box>
                          </MenuItem>
                        </MenuList>
                      </Portal>
                    </Menu>
                  </InputLeftAddon>

                  <Input
                    id="amount"
                    type="number"
                    placeholder="0.0"
                    value={formik.values.amount}
                    onChange={handleInputChange}
                    color="white"
                    sx={{
                      width: "100%",
                      height: "60px",
                      fontSize: "md",
                      borderColor: "#403D3B",
                      borderWidth: "0.1px",
                      textAlign: "right",
                      _placeholder: { color: "#595959" },
                    }}
                  />

                </InputGroup>

                <Flex justifyContent="center" alignItems="center">
                  <ArrowDownIcon color="grey" boxSize={6} />
                </Flex>

                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                  <Text fontSize="md" fontWeight="semibold" color="white">
                    TO RECEIVE
                  </Text>
                  <Text fontSize="xs" fontWeight="light" color="#9A9998">
                    0% Slippage
                  </Text>
                </Flex>

                <InputGroup
                  mb={4}
                  sx={{
                    borderRadius: "8px",
                    borderColor: "gray.500",
                  }}
                >
                  <InputLeftAddon
                    children={
                      <Box display="flex" alignItems="center">
                        <Image
                          src="/kySOLLogo.svg"
                          boxSize="30px"
                          ml={2}
                          borderRadius="full"
                          border="2px solid #222F44"
                        />
                        <Text fontWeight="bold" ml={2} color="white">kySOL</Text>
                      </Box>
                    }
                    sx={{
                      color: "white.200",
                      paddingX: "12px",      // Match padding with input box
                      fontSize: "md",        // Match font size
                      height: "60px",        // Match height with input box
                      display: "flex",
                      alignItems: "center",  // Center the text vertically
                      borderWidth: "0.1px",
                      borderColor: "#403D3B",
                      width: "150px",
                    }}
                  />
                  <Input
                    id="receive"
                    placeholder="0.0"
                    value={formik.values.receivedAmount}
                    readOnly
                    color="white"
                    sx={{
                      width: "100%",        // Makes the input take up full width within InputGroup
                      height: "60px",       // Set height to match addon
                      fontSize: "md",       // Set font size to match addon
                      borderColor: "#403D3B",
                      borderWidth: "0.1px",
                      textAlign: "right",     // Aligns the text to the right
                      _placeholder: { color: "595959" },
                    }}
                  />
                </InputGroup>


                {!publicKey ? (
                  <Flex justifyContent="center" width="100%">
                    <Button
                      width="100%"
                      className="mint-box-button"
                      isDisabled={connecting}
                      onClick={() => setVisible(true)}
                    >
                      {connecting ? "Connecting..." : "Connect Wallet"}
                    </Button>
                  </Flex>
                ) : (
                  <Flex justifyContent="center" width="100%">
                    <Button width="100%" className="mint-box-button" isDisabled={formDisabled || solVaultPaused || capReached} isLoading={formik.isSubmitting} type="submit">
                      {capReached ? "Cap filled" : "Convert to kySOL"}
                    </Button>
                  </Flex>
                )}
              </form>
              
              { kySolRate && (
                <Flex className="exchange-info" mt={4}>
                  <Text>Exchange rate:</Text>
                  <Text ml={2}>{selectedToken === "sol" ? `1 SOL = ${jitoSolRate ? (jitoSolRate  * kySolRate).toFixed(5) : (1 / estimatedJitoSolRate).toFixed(5)} kySOL` : `1 JitoSOL = ${kySolRate?.toFixed(5) ?? 1} kySOL`}</Text>
                </Flex>
              )}
            </Box>

            <NcnTable ncns={ncns ?? []} />

          </VStack >
        </Container >
      </Flex >
    </>
  );
};
