import { Box, Flex, Table, Th, Tr, Text, TableContainer, Tbody, Td, Thead, Image, Link, HStack, Badge, Spinner, Tooltip, VStack, Skeleton } from "@chakra-ui/react";
import { DeFiOpportunities, Points } from "../../rest/kairos/types";
import { usdAmountFormatter } from "../../helpers/formatter";
import { InfoIcon } from "@chakra-ui/icons";
import mixpanel from 'mixpanel-browser';

interface DeFiTableProps {
    opportunities: DeFiOpportunities[];
    points: Points | null;
    isLoading: boolean;
}

export const DeFiTable = ({ opportunities, isLoading, points }: DeFiTableProps) => {
    const handleRowClick = (opportunity: DeFiOpportunities) => {
        mixpanel.track('DeFi Opportunity Clicked', {
            id: opportunity.id,
            protocol: opportunity.protocol,
            chain: opportunity.chain,
        });
    };

    return (
        <Box bg="#18181b" p={8} className="mint-box">
            <Flex justifyContent="space-between" mb={6}>
                <Text fontSize="md" fontWeight="semibold" color="white">
                    DeFi Opportunities
                </Text>
            </Flex>
            {!isLoading && opportunities.length > 0 ? (
                <Flex direction={"column"}>
                    <TableContainer mt={2}>
                        <Table variant={"info"}>
                            <Thead>
                                <Tr>
                                    <Th>Assets</Th>
                                    <Th>Protocol</Th>
                                    <Th>Boost</Th>
                                    <Th>TVL</Th>
                                    <Th>Points</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {opportunities.map((opportunity, index) => (
                                    <Link
                                        href={opportunity.protocolUrl}
                                        isExternal
                                        _hover={{ textDecoration: 'none' }}
                                        key={`${index}`}
                                        display={"contents"}
                                        onClick={() => handleRowClick(opportunity)}
                                    >
                                        <Tr key={`${index}`} height={"75px"} sx={{ _hover: { backgroundColor: "whiteAlpha.200" }, transition: "background-color 0.3s ease", marginBottom: "20px" }}>
                                            <Td textColor={"white"} verticalAlign="middle">
                                                <VStack alignItems={"flex-start"} spacing={2}>
                                                    <HStack>
                                                        <Image src={opportunity.assetLogo} alt={opportunity.asset} width={5} height={5} borderRadius="full" />
                                                        <Text>{opportunity.asset}</Text>
                                                    </HStack>
                                                    {opportunity.apy && (
                                                        <HStack>
                                                            {opportunity.apy.map((apy) => (
                                                                apy.apy > 0 && (
                                                                    <Badge key={apy.name} colorScheme="blue" px={2} py={1} fontSize="xs" height="auto">{apy.name ? `${apy.name}:` : ""} {(apy.apy * 100).toFixed(2)}%</Badge>
                                                                )
                                                            ))}
                                                        </HStack>
                                                    )}
                                                </VStack>
                                            </Td>
                                            <Td textColor={"white"} verticalAlign="middle">
                                                <HStack>
                                                    <Image src={opportunity.protocolLogo} alt={opportunity.protocol} width={5} height={5} borderRadius="full" />
                                                    <Text>{opportunity.protocol}</Text>
                                                </HStack>
                                            </Td>
                                            <Td textColor={"white"} verticalAlign="middle">
                                                <Badge colorScheme="green" px={2} py={1} fontSize="md" height="auto">{opportunity.multiplier}x</Badge>
                                            </Td>
                                            <Td textColor={"white"} verticalAlign="middle">
                                                {opportunity.tvl > 0 ? (
                                                    <HStack>
                                                        <Text>$</Text>
                                                        <Text>{usdAmountFormatter.format(Number(opportunity.tvl))}</Text>
                                                    </HStack>
                                                ) : (
                                                    <Skeleton width={"80px"} height={"20px"} />
                                                )}
                                            </Td>
                                            <Td textColor={"white"} verticalAlign="middle">
                                                {(points && opportunity.pointsKey) && (
                                                    <HStack spacing={2}>
                                                        <Text>{points[opportunity.pointsKey as keyof Points].toLocaleString(undefined, { maximumFractionDigits: 0 })}</Text>
                                                        {opportunity.info && (
                                                            <Tooltip label={opportunity.info}>
                                                                <InfoIcon style={{ position: 'relative', top: '-1px' }} />
                                                            </Tooltip>
                                                        )}
                                                    </HStack>
                                                )}
                                                {!points && (
                                                    <Text>👀</Text>
                                                )}
                                                {(points && !opportunity.pointsKey) && (
                                                    <HStack spacing={3}>
                                                        <Text>👀</Text>
                                                        <Tooltip label="Points counter is coming soon! Rest assured, your points are safe and will be distributed retroactively.">
                                                            <InfoIcon style={{ position: 'relative', top: '-1px' }} />
                                                        </Tooltip>
                                                    </HStack>
                                                )}
                                            </Td>
                                            <Td width={"0px"}></Td> {/* The table won't display the last column without this */}
                                        </Tr>
                                    </Link>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Flex>
            ) : (
                isLoading ? (
                    <Spinner />
                ) : (
                    <Text fontSize="md" fontWeight="400" color="#CCCCCC">
                        The realm is quiet, adventurer. There are no DeFi opportunities available at this moment. Stay vigilant and return soon—new paths may open before long!
                    </Text>
                )
            )}
        </Box>
    )
};