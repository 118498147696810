import { useContext, useState } from 'react';
import { PublicKey, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useConnection } from '@solana/wallet-adapter-react';
import { Idl, Program, BN } from "@coral-xyz/anchor";
import * as jitoIdl from "../idl/jito_vault_v3.json";
import { AppConfigContext } from '../context';

interface NodeOperatorId {
  name: string;
  id: string;
  image: string | null;
  kySol: boolean;
  kyJto: boolean;
}

export interface NodeOperator {
  name: string;
  id: string;
  image: string;
  kySOLStaked: string | null;
  kySOLCooling: string | null;
  kySOLQueued: string | null;
  kyJTOStaked: string | null;
  kyJTOCooling: string | null;
  kyJTOQueued: string | null;
  kySol: boolean;
  kyJto: boolean;
}

interface DelegationState {
  stakedAmount: BN;
  coolingDownAmount: BN;
  enqueuedForCooldownAmount: BN;
}

export const useNodeOperators = () => {
  const { connection } = useConnection();
  const { solVaultAddress, jtoVaultAddress } = useContext(AppConfigContext);

  const [nodeOperators, setNodeOperators] = useState<NodeOperator[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchDelegationState = async (
    program: Program,
    vaultAddress: string,
    operatorId: string
  ): Promise<DelegationState | null> => {
    try {
      const vaultProgram = new PublicKey("Vau1t6sLNxnzB7ZDsef8TLbPLfyZMYXH8WTNqUdm9g8");
      const vault = new PublicKey(vaultAddress);
      const operator = new PublicKey(operatorId);
      const seeds = [
        Buffer.from("vault_operator_delegation"),
        vault.toBuffer(),
        operator.toBuffer(),
      ];
      const [pda] = PublicKey.findProgramAddressSync(seeds, vaultProgram);

      // @ts-ignore
      const vaultOperatorDelegation = await program.account.vaultOperatorDelegation.fetch(pda);
      return vaultOperatorDelegation.delegationState;
    } catch (err) {
      console.error(`Error fetching delegation state for operator ${operatorId}:`, err);
      return null;
    }
  };

  const fetchNodeOperators = async () => {
    setIsLoading(true);
    setError(null);

    const nos: NodeOperatorId[] = [
      {
        name: "Kiln",
        id: "29rxXT5zbTR1ctiooHtb1Sa1TD4odzhQHsrLz3D78G5w",
        image: null,
        kySol: true,
        kyJto: true
      },
      {
        name: "Luganodes",
        id: "LKFpfXtBkH5b7D9mo8dPcjCLZCZpmLQC9ELkbkyVdah",
        image: null,
        kySol: true,
        kyJto: true
      },
      {
        name: "Helius",
        id: "BFEsrxFPsBcY2hR5kgyfKnpwgEc8wYQdngvRukLQXwG2",
        image: null,
        kySol: true,
        kyJto: true
      },
      {
        name: "Temporal",
        id: "CA8PaNSoFWzvbCJ2oK3QxBEutgyHSTT5omEptpj8YHPY",
        image: "https://www.jito.network/_next/image/?url=%2Frestaking%2Foperators%2Ftemporal.png&w=128&q=75",
        kySol: true,
        kyJto: true
      },
      {
        name: "Laine",
        id: "859tThorUu4uskw4yXSHkW9xqeCJmG5vm4KXhjWirLwL",
        image: null,
        kySol: true,
        kyJto: true
      },
      {
        name: "Pier Two",
        id: "GZxp4e2Tm3Pw9GyAaxuF6odT3XkRM96jpZkp3nxhoK4Y",
        image: null,
        kySol: true,
        kyJto: true
      },
      {
        name: "Everstake",
        id: "FzZ9EXmHv7ANCXijpALUBzCza6wYNprnsfaEHuoNx9sE",
        image: null,
        kySol: true,
        kyJto: true
      },
      {
        name: "Infstones",
        id: "5TGRFaLy3eF93pSNiPamCgvZUN3gzdYcs7jA3iCAsd1L",
        image: null,
        kySol: true,
        kyJto: true
      },
      {
        name: "Staking Facilities",
        id: "EkroMQiZJfphVd9iPvR4zMCHasTW72Uh1mFYkTxtQuY6",
        image: "https://www.jito.network/_next/image/?url=%2FvalidatorImages%2Fstakingfac.jpg&w=64&q=75",
        kySol: false,
        kyJto: true
      }
    ];

    const nodeOperators: NodeOperator[] = [];

    try {
      const program = new Program(jitoIdl as Idl, { connection });

      for (const no of nos) {
        const nodeOperator: NodeOperator = {
          name: no.name,
          id: no.id,
          image: no.image ?? `https://www.jito.network/restaking/operators/${no.name.replace(" ", "").toLowerCase()}.svg`,
          kySOLStaked: null,
          kySOLCooling: null,
          kySOLQueued: null,
          kyJTOStaked: null,
          kyJTOCooling: null,
          kyJTOQueued: null,
          kySol: no.kySol,
          kyJto: no.kyJto
        };

        // Fetch kySOL delegation state
        const solDelegationState = await fetchDelegationState(program, solVaultAddress, no.id);
        if (solDelegationState && no.kySol) {
          nodeOperator.kySOLStaked = solDelegationState.stakedAmount.div(new BN(LAMPORTS_PER_SOL)).toString();
          nodeOperator.kySOLCooling = solDelegationState.coolingDownAmount.div(new BN(LAMPORTS_PER_SOL)).toString();
          nodeOperator.kySOLQueued = solDelegationState.enqueuedForCooldownAmount.div(new BN(LAMPORTS_PER_SOL)).toString();
        }

        // Fetch kyJTO delegation state
        const jtoDelegationState = await fetchDelegationState(program, jtoVaultAddress, no.id);
        if (jtoDelegationState && no.kyJto) {
          nodeOperator.kyJTOStaked = jtoDelegationState.stakedAmount.div(new BN(LAMPORTS_PER_SOL)).toString();
          nodeOperator.kyJTOCooling = jtoDelegationState.coolingDownAmount.div(new BN(LAMPORTS_PER_SOL)).toString();
          nodeOperator.kyJTOQueued = jtoDelegationState.enqueuedForCooldownAmount.div(new BN(LAMPORTS_PER_SOL)).toString();
        }

        nodeOperators.push(nodeOperator);
      }

      // Vault
      // @ts-ignore
      const solVault = await program.account.vault.fetch(new PublicKey(solVaultAddress));
      // @ts-ignore
      const jtoVault = await program.account.vault.fetch(new PublicKey(jtoVaultAddress));

      nodeOperators.push({
        name: "Vault",
        id: "",
        image: "https://pbs.twimg.com/profile_images/1847426788252590080/-Tb-I1Yl_400x400.jpg",
        kySOLStaked: solVault.vrtSupply.div(new BN(LAMPORTS_PER_SOL)).sub(nodeOperators.reduce((acc, curr) => acc.add(new BN(curr.kySOLStaked || "0").add(new BN(curr.kySOLCooling || "0")).add(new BN(curr.kySOLQueued || "0"))), new BN(0))).toString(),
        kySOLCooling: solVault.vrtCoolingDownAmount.div(new BN(LAMPORTS_PER_SOL)).toString(),
        kySOLQueued: solVault.vrtEnqueuedForCooldownAmount.div(new BN(LAMPORTS_PER_SOL)).add(solVault.vrtReadyToClaimAmount.div(new BN(LAMPORTS_PER_SOL))).toString(),
        kyJTOStaked: jtoVault.vrtSupply.div(new BN(LAMPORTS_PER_SOL)).sub(nodeOperators.reduce((acc, curr) => acc.add(new BN(curr.kyJTOStaked || "0").add(new BN(curr.kyJTOCooling || "0")).add(new BN(curr.kyJTOQueued || "0"))), new BN(0))).toString(),
        kyJTOCooling: jtoVault.vrtCoolingDownAmount.div(new BN(LAMPORTS_PER_SOL)).toString(),
        kyJTOQueued: jtoVault.vrtEnqueuedForCooldownAmount.div(new BN(LAMPORTS_PER_SOL)).add(jtoVault.vrtReadyToClaimAmount.div(new BN(LAMPORTS_PER_SOL))).toString(),
        kySol: true,
        kyJto: true
      });

      setNodeOperators(nodeOperators);
    } catch (error) {
      console.error("Error fetching node operators:", error);
      setError("Error fetching node operators.");
    } finally {
      setIsLoading(false);
    }
  };

  return { nodeOperators, isLoading, error, fetchNodeOperators };
};
